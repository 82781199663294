import {ref} from "vue";

export function partners() {
    return {
        partners: ref([
            /*reactive({
                pic: null,
                name: null
            }),*/
        ])
    }
}
